import ActionBar from "../shared/components/ActionBar";
import Calculator from "../shared/components/Calculator";
import Visualizations from "../shared/components/Visualizations";

const Home = () => {
  return (
    <div className="main-container">
      <Calculator />
      <Visualizations />
      <ActionBar />
    </div>
  );
};

export default Home;
