// Cephalic imports
import { ReactComponent as COA } from "../../resources/images/baby-body/cephalic/OA.svg";
import { ReactComponent as COP } from "../../resources/images/baby-body/cephalic/OP.svg";
import { ReactComponent as CLOA1 } from "../../resources/images/baby-body/cephalic/LOA1.svg";
import { ReactComponent as CLOA2 } from "../../resources/images/baby-body/cephalic/LOA2.svg";
import { ReactComponent as CLOP4 } from "../../resources/images/baby-body/cephalic/LOP4.svg";
import { ReactComponent as CLOP5 } from "../../resources/images/baby-body/cephalic/LOP5.svg";
import { ReactComponent as CLOT } from "../../resources/images/baby-body/cephalic/LOT.svg";
import { ReactComponent as CROA10 } from "../../resources/images/baby-body/cephalic/ROA10.svg";
import { ReactComponent as CROA11 } from "../../resources/images/baby-body/cephalic/ROA11.svg";
import { ReactComponent as CROP7 } from "../../resources/images/baby-body/cephalic/ROP7.svg";
import { ReactComponent as CROP8 } from "../../resources/images/baby-body/cephalic/ROP8.svg";
import { ReactComponent as CROT } from "../../resources/images/baby-body/cephalic/ROT.svg";

// Breech imports
import { ReactComponent as BSA } from "../../resources/images/baby-body/breech/SA.svg";
import { ReactComponent as BSP } from "../../resources/images/baby-body/breech/SP.svg";
import { ReactComponent as BLSA1 } from "../../resources/images/baby-body/breech/LSA1.svg";
import { ReactComponent as BLSA2 } from "../../resources/images/baby-body/breech/LSA2.svg";
import { ReactComponent as BLSP4 } from "../../resources/images/baby-body/breech/LSP4.svg";
import { ReactComponent as BLSP5 } from "../../resources/images/baby-body/breech/LSP5.svg";
import { ReactComponent as BLST } from "../../resources/images/baby-body/breech/LST.svg";
import { ReactComponent as BRSA10 } from "../../resources/images/baby-body/breech/RSA10.svg";
import { ReactComponent as BRSA11 } from "../../resources/images/baby-body/breech/RSA11.svg";
import { ReactComponent as BRSP7 } from "../../resources/images/baby-body/breech/RSP7.svg";
import { ReactComponent as BRSP8 } from "../../resources/images/baby-body/breech/RSP8.svg";
import { ReactComponent as BRST } from "../../resources/images/baby-body/breech/RST.svg";
import { BabyBodyPositions } from "../helpers/Enums";

interface Props {
  position: string;
  rotation: string;
}

// About the acronyms: they are related to fetal positioning or belly mapping
// http://www.earthboundtherapeutics.com/blog/fetal-positioning-aka-belly-mapping
// The initial C is for Cephalic (Hodeleie) and the B is for Breech (Sete/Foteleie)
const babyBodyImages: { [key: string]: React.ComponentType } = {
  BSA,
  BSP,
  BLSA1,
  BLSA2,
  BLSP4,
  BLSP5,
  BLST,
  BRSA10,
  BRSA11,
  BRSP7,
  BRSP8,
  BRST,
  COA,
  COP,
  CLOA1,
  CLOA2,
  CLOP4,
  CLOP5,
  CLOT,
  CROA10,
  CROA11,
  CROP7,
  CROP8,
  CROT,
};

const BabyBody = ({ position, rotation }: Props) => {
  let shortPosition = position === BabyBodyPositions.Cephalic ? "C" : "B";
  const BabyBodyComponent = babyBodyImages[shortPosition + rotation];
  return <BabyBodyComponent />;
};

export default BabyBody;
