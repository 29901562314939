import { useEffect, useState } from "react";

// This custom React hook, UseScreenOrientation, tracks and provides the current screen orientation.
// It initializes the orientation state with the current screen orientation and sets up an event listener
// for orientation changes. When the orientation changes, the state is updated. The hook also ensures
// that the event listener is removed when the component using the hook unmounts.
const getOrientation = () => window.screen.orientation.type;

const UseScreenOrientation = () => {
  const [orientation, setOrientation] = useState(getOrientation());

  const updateOrientation = () => {
    setOrientation(getOrientation());
  };

  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  return orientation;
};

export default UseScreenOrientation;
