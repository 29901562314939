import {
  ModalDialog,
  Size,
  SystemIcons,
  Table,
  TableColumn,
} from "@laerdal/life-react-components";
import { useEffect, useState } from "react";
import { LabourProgressDto } from "../../dtos/LabourProgressDto";
import {
  LocalStorageKey,
  refreshLabourDataFromLocalStorage,
  useLocalStorage,
} from "../../helpers/LocalStorage";
import DropDownDatasources from "../../helpers/DropDownDatasources";
import Global from "../../helpers/Global";
import useLabourViewStore from "../../helpers/StateManager";

interface Props {
  isWindowOpen: boolean;
  setIsWindowOpen: (newValue: boolean) => void;
}

// Interface defining the structure of a row in the data table
interface DataTableRow {
  id: number; // Unique identifier for each row
  timestamp: string; // Timestamp of the labour progress assessment
  descentLevel: string; // Baby's descent level during labour
  cervixOpening: string; // Cervix opening measurement during labour
  headRotationPosition: string; // Baby's head rotation position during labour
  babyBodyPosition: string; // Baby's body position during labour
}

// The ViewDataModal component displays a modal showing labour progress data from local storage,
// allowing users to view and delete entries. It manages data updates and renders a table with
// timestamps, descent levels, cervix openings, and baby body positions. The component uses local
// storage to store and retrieve data, and updates the display based on the baby body position state.
const ViewDataModal = ({ isWindowOpen, setIsWindowOpen }: Props) => {
  // Variables

  // Global state managers

  // State manager for the baby body position.
  const babyBodyPositionFromState = useLabourViewStore(
    (state) => state.babyBodyPosition
  );

  // State manager for refresihng labour progress data from storage.
  const refreshLabourProgressDataFromStorage = useLabourViewStore(
    (state) => state.refreshLabourProgressDataFromStorage
  );
  const setRefreshLabourProgressDataFromStorage = useLabourViewStore(
    (state) => state.setRefreshLabourProgressDataFromStorage
  );

  // Others
  const [labourProgressData, setLabourProgressData] = useLocalStorage(
    LocalStorageKey.LabourProgressDataArray,
    []
  );
  const [lastUpdatedDay, setLastUpdatedDay] = useState("");
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");

  // Functions

  // Function to close the register data modal window
  const CloseRegisterDataModal = () => {
    // Indicate modal closure by setting openWindow flag to false
    isWindowOpen = false;
    // Update state to close the modal window
    setIsWindowOpen(false);
  };

  // Function to delete an item from the labour progress data list
  const DeleteItemFromList = (index: number) => {
    // Remove the item from the array at the specified index
    labourProgressData.splice(index, 1);
    // Update local storage with the modified array
    setLabourProgressData(labourProgressData);
    // Set flag to refresh data from storage
    setRefreshLabourProgressDataFromStorage(true);
  };

  // Function to get the column definitions for the data table
  const GetDataTableColumns = (): TableColumn[] => {
    return [
      {
        key: "timestamp",
        name: "Tidspunkt",
        sortable: true,
        type: "text",
      },
      {
        key: "babyBodyPosition",
        name: "Presentasjon",
        type: "text",
        sortable: true,
      },
      {
        key: "descentLevel",
        name: "Nivå",
        type: "number",
        sortable: true,
      },
      {
        key: "cervixOpening",
        name: "Mormunnsåpning",
        sortable: true,
        type: "text",
      },
      {
        key: "headRotationPosition",
        name: "Posisjon",
        sortable: true,
        type: "text",
      },
      {
        key: "trashIcon",
        name: "",
        type: "icon",
        sortable: false,
        icon: <SystemIcons.Delete />,
        action: (row: DataTableRow) => {
          DeleteItemFromList(row.id);
        },
      },
    ];
  };

  // Function to get the rows for the data table
  const getDataTableRows = () => {
    var temporaryRows: DataTableRow[] = [];
    // Get the display values for baby rotation position based on the baby body position state
    var babyRotationPositionValues =
      DropDownDatasources.getBabyRotationByCephalicPositionValues().concat(
        DropDownDatasources.getBabyRotationByBreechPositionValues()
      );

    // Iterate through the labour progress data array to populate the temporary rows array
    labourProgressData.forEach((item: LabourProgressDto, index: number) => {
      temporaryRows.push({
        id: index,
        timestamp: Global.formatTimeFromString(item.assessmentTime),
        babyBodyPosition: item.babyBodyPosition,
        descentLevel: item.babyBodyDescentLevel,
        cervixOpening: `${item.cervixOpeningLevel} cm`,
        headRotationPosition:
          babyRotationPositionValues.find(
            (t) => t.value === item.babyBodyRotation
          )?.displayLabel || "",
      });
    });
    return temporaryRows;
  };

  // Effects
  useEffect(() => {
    // Refresh labour progress data from local storage if the flag is set
    if (refreshLabourProgressDataFromStorage) {
      setLabourProgressData(refreshLabourDataFromLocalStorage());
      // Reset flag after updating data from storage
      setRefreshLabourProgressDataFromStorage(false);
    }

    // Set the last updated time/date if there is data in the array
    if (labourProgressData.length > 0) {
      setLastUpdatedTime(
        labourProgressData[labourProgressData.length - 1].assessmentTime
      );
      setLastUpdatedDay(
        labourProgressData[labourProgressData.length - 1]
          .isAssessmentOnTheDayBefore
          ? "i går"
          : "i dag"
      );
    }
  }, [
    babyBodyPositionFromState,
    labourProgressData,
    isWindowOpen,
    refreshLabourProgressDataFromStorage,
    setLabourProgressData,
    setRefreshLabourProgressDataFromStorage,
  ]);

  return (
    <ModalDialog
      key="RegisterDataModalDialog"
      title="Registrerte data"
      size={Size.Large}
      isModalOpen={isWindowOpen}
      closeAction={CloseRegisterDataModal}
      shouldCloseOnOverlayClick={false}
      submitAction={() => {}}
    >
      <div className="view-data-container">
        <div className="sub-title">
          {`Sist oppdatert: ${
            labourProgressData.length > 0 && lastUpdatedTime
              ? `${lastUpdatedDay}, ${Global.formatTimeFromString(
                  lastUpdatedTime
                )}`
              : "---"
          }`}
        </div>
        <Table
          columns={GetDataTableColumns()}
          border={true}
          rows={getDataTableRows()}
          noRowsLabel="Ingen data funnet"
          // Accordion is set to true to prevent showing a footer in the table. After "removing" the footer,
          // the table needs a border bottom, which is handled in the CSS
          accordion={true}
        />
      </div>
    </ModalDialog>
  );
};

export default ViewDataModal;
