import { Button, ModalDialog, Size } from "@laerdal/life-react-components";
import { useState } from "react";
import ProgressPlayer from "./ProgressPlayer";
import RegisterDataModal from "./modals/RegisterDataModal";
import ViewDataModal from "./modals/ViewDataModal";
import "../../resources/styles/ActionBar.css";
import useLabourViewStore from "../helpers/StateManager";

const ActionBar = () => {
  // Variables
  const [openRegisterDataModalWindow, setOpenRegisterDataModalWindow] =
    useState(false);
  const [openViewDataModalWindow, setOpenViewDataModalWindow] = useState(false);
  const [openResetDataModalWindow, setOpenResetDataModalWindow] =
    useState(false);

  // Global state managers

  // State manager for resetting the calculator data.
  const setResetCalculatorData = useLabourViewStore(
    (state) => state.setResetCalculatorData
  );

  // State manager for resetting the visualization data.
  const setResetVisualizationsData = useLabourViewStore(
    (state) => state.setResetVisualizationsData
  );

  // State manager for refreshing labour progress data from storage.
  const setRefreshLabourProgressDataFromStorage = useLabourViewStore(
    (state) => state.setRefreshLabourProgressDataFromStorage
  );

  return (
    <>
      <div className="actionbar-container">
        <div className="button-container">
          <Button
            className=""
            variant="primary"
            size={Size.Medium}
            width="146px"
            onClick={() => {
              // Opening the Register data modal window
              setOpenRegisterDataModalWindow(true);
            }}
          >
            Registrer
          </Button>
        </div>
        <ProgressPlayer />
        <div className="button-container">
          <Button
            className=""
            variant="secondary"
            size={Size.Medium}
            width="146px"
            onClick={() => {
              // Opening the View data modal window
              setOpenViewDataModalWindow(true);
            }}
          >
            Vis data
          </Button>
        </div>
        <div className="button-container">
          <Button
            className=""
            variant="secondary"
            size={Size.Medium}
            width="146px"
            onClick={() => {
              // Opening the Reset data modal window
              setOpenResetDataModalWindow(true);
            }}
          >
            Avslutt
          </Button>
        </div>
      </div>
      <>
        <ModalDialog
          key="AvsluttModalDialog"
          title={"Avslutt?"}
          size={Size.Medium}
          isModalOpen={openResetDataModalWindow}
          closeAction={() => {
            setOpenResetDataModalWindow(false);
          }}
          submitAction={() => {}}
          shouldCloseOnOverlayClick={false}
          buttons={[
            {
              action: () => {
                // Closing the Reset data modal window
                setOpenResetDataModalWindow(false);
              },
              text: "Nei, fortsett",
              variant: "tertiary",
            },
            {
              action: () => {
                // Resetting both calculator and visualization data
                setResetCalculatorData(true);
                setResetVisualizationsData(true);
                setRefreshLabourProgressDataFromStorage(true);

                // Closing the Reset data modal window
                setOpenResetDataModalWindow(false);
              },
              text: "Ja, avslutt",
              variant: "critical",
            },
          ]}
        >
          Data fra denne fødselen vil bli slettet.
        </ModalDialog>
        <RegisterDataModal
          openWindow={openRegisterDataModalWindow}
          setOpenWindow={setOpenRegisterDataModalWindow}
        />
        <ViewDataModal
          isWindowOpen={openViewDataModalWindow}
          setIsWindowOpen={setOpenViewDataModalWindow}
        />
      </>
    </>
  );
};

export default ActionBar;
