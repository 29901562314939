import React from "react";
import { ReactComponent as Synclitic } from "../../resources/images/baby-birth-position/Synclitic.svg";
import { ReactComponent as RightAsynclitic } from "../../resources/images/baby-birth-position/RightAsynclitic.svg";
import { ReactComponent as LeftAsynclitic } from "../../resources/images/baby-birth-position/LeftAsynclitic.svg";
import { ReactComponent as Breech } from "../../resources/images/baby-birth-position/Breech.svg";

interface Props {
  position: string;
}

const babyBirthPosition: { [key: string]: React.ComponentType } = {
  Sentrert: Synclitic,
  HoyreAsynklitisk: RightAsynclitic,
  VenstreAsynklitisk: LeftAsynclitic,
  Breech: Breech,
};

const BabyBirthPosition = ({ position }: Props) => {
  const BabyBirthPositionComponent = babyBirthPosition[position];
  return <BabyBirthPositionComponent />;
};

export default BabyBirthPosition;
