import { create } from "zustand";
import {
  BabyBodyPositions,
  BabyHeadPositions,
  CephalicPositions,
} from "./Enums";

// Define the structure of the Labour View store's state
interface LabourViewStoreState {
  // Manages a boolean state to reset calculator data when the 'avslutt' button in the ActionBar is clicked.
  // Connects the ActionBar with the Calculator component.
  resetCalculatorData: boolean;
  setResetCalculatorData: (value: boolean) => void;

  // Manages a boolean state to reset visualization values when the 'avslutt' button in the ActionBar is clicked.
  // Connects the ActionBar with the Visualization component.
  resetVisualizationsData: boolean;
  setResetVisualizationsData: (value: boolean) => void;

  // Manages the baby head position value shared between the descent and rotation visualization components.
  babyHeadPosition: string;
  setBabyHeadPosition: (value: string) => void;

  // Manages the baby body position value shared between the descent and rotation visualization components.
  babyBodyPosition: string;
  setBabyBodyPosition: (value: string) => void;

  // Manages the baby body rotation value shared between the descent and rotation visualization components.
  babyBodyRotation: string;
  setBabyBodyRotation: (value: string) => void;

  // Manages the cervix opening value shared between the descent and rotation visualization components.
  cervixOpening: string;
  setCervixOpening: (value: string) => void;

  // Manages the cervix descent value used in the descent visualization component.
  babyDescentLevel: number;
  setBabyDescentLevel: (value: number) => void;

  // Manages the refresh of labour data in the state. This is triggered when users add or delete
  // observations in local storage, which updates the visualizations and the progress player component.
  refreshLabourProgressDataFromStorage: boolean;
  setRefreshLabourProgressDataFromStorage: (value: boolean) => void;

  // Manages the progress player status (active or not) and controls an overlay element in the DOM.
  // When active, this overlay isolates the visualization components from the progress player,
  // focusing attention on the animation and progress player.
  isPlayerModeActive: boolean;
  setIsPlayerModeActive: (value: boolean) => void;

  // Manages the active state of the date-time picker.
  // When active (true), it displays an overlay to isolate the date-time picker from other components,
  // ensuring that focus remains on the picker and its animation.
  isDateTimePickerActive: boolean;
  setIsDateTimePickerActive: (value: boolean) => void;
}

// Create the Labour View Zustand store with the initial state
const useLabourViewStore = create<LabourViewStoreState>((set) => ({
  resetCalculatorData: false,
  setResetCalculatorData: (value: boolean) =>
    set({ resetCalculatorData: value }),
  resetVisualizationsData: false,
  setResetVisualizationsData: (value: boolean) =>
    set({ resetVisualizationsData: value }),
  babyHeadPosition: BabyHeadPositions.Centered,
  setBabyHeadPosition: (value: string) => set({ babyHeadPosition: value }),
  babyBodyPosition: BabyBodyPositions.Cephalic,
  setBabyBodyPosition: (value: string) => set({ babyBodyPosition: value }),
  babyBodyRotation: CephalicPositions.OcciputAnterior,
  setBabyBodyRotation: (value: string) => set({ babyBodyRotation: value }),
  cervixOpening: "5",
  setCervixOpening: (value: string) => set({ cervixOpening: value }),
  babyDescentLevel: -5,
  setBabyDescentLevel: (value: number) => set({ babyDescentLevel: value }),
  refreshLabourProgressDataFromStorage: false,
  setRefreshLabourProgressDataFromStorage: (value: boolean) =>
    set({ refreshLabourProgressDataFromStorage: value }),
  isPlayerModeActive: false,
  setIsPlayerModeActive: (value: boolean) => set({ isPlayerModeActive: value }),
  isDateTimePickerActive: false,
  setIsDateTimePickerActive: (value: boolean) =>
    set({ isDateTimePickerActive: value }),
}));

export default useLabourViewStore;
