import React from "react";
import { useCallback, useEffect, useState } from "react";
import {
  LocalStorageKey,
  refreshLabourDataFromLocalStorage,
  useLocalStorage,
} from "../helpers/LocalStorage";
import { ToastMessageType, Visibility } from "../helpers/Enums";
import { LabourProgressDto } from "../dtos/LabourProgressDto";
import ProgressPlayerCalculation from "../helpers/ProgressPlayerCalculation";
import { COLORS, SystemIcons, useToast } from "@laerdal/life-react-components";
import Global from "../helpers/Global";
import useLabourViewStore from "../helpers/StateManager";

// The progress player component visualizes the progression of labour assessments over time on a horizontal timeline.
const ProgressPlayer = () => {
  // Variables

  // Local storage data
  const [labourProgressData, setLabourProgressData] = useLocalStorage(
    LocalStorageKey.LabourProgressDataArray,
    []
  );

  // Global state managers

  // State manager for refresihng labour progress data from storage.
  const refreshLabourProgressDataFromStorage = useLabourViewStore(
    (state) => state.refreshLabourProgressDataFromStorage
  );
  const setRefreshLabourProgressDataFromStorage = useLabourViewStore(
    (state) => state.setRefreshLabourProgressDataFromStorage
  );

  // State manager for the baby body position.
  const setBabyBodyPositionFromState = useLabourViewStore(
    (state) => state.setBabyBodyPosition
  );

  // State manager for the baby head position.
  const setBabyHeadPositionFromState = useLabourViewStore(
    (state) => state.setBabyHeadPosition
  );

  // State manager for the current level of descent for the baby.
  const setBabyDescentLevelFromState = useLabourViewStore(
    (state) => state.setBabyDescentLevel
  );

  // State manager for the cervix opening.
  const setCervixOpeningFromState = useLabourViewStore(
    (state) => state.setCervixOpening
  );

  // State manager for the baby body rotation.
  const setBabyBodyRotationFromState = useLabourViewStore(
    (state) => state.setBabyBodyRotation
  );

  // State manager for tracking if the player mode is active.
  const isPlayerModeActive = useLabourViewStore(
    (state) => state.isPlayerModeActive
  );
  const setIsPlayerModeActive = useLabourViewStore(
    (state) => state.setIsPlayerModeActive
  );

  // Global

  // Width of the timeline bar.
  const progressBarWidth = 390;
  // Starting X-axis position of the timeline bar.
  const initialTimelineBarPosX = 56;
  // Adjusting for decimal precision: An extra pixel buffer from decimal calculations needs to be subtracted
  // from the X position of all objects on the timeline.
  const extraBufferDueToPxCalculation = 2;
  // Initial Position (X axis) of the progress circle object
  const initialProgressCirclePosX = 57;
  // Visibility of the progress circle: It is displayed only if there is at least one observation registered.
  const progressCircleVisibility =
    labourProgressData.length > 0 ? Visibility.Visible : Visibility.Hidden;
  // Current progress circle position (X-axis): If only one observation is registered, the circle starts at the beginning of the timeline.
  // Otherwise, it is placed at the end.
  const [currentProgressCirclePosX, setCurrentProgressCirclePosX] = useState(
    initialProgressCirclePosX +
      (labourProgressData.length > 1
        ? progressBarWidth - extraBufferDueToPxCalculation
        : 0)
  );
  // Width of the current timeline progress line.
  let [currentTimelineProgressWidth, setCurrentTimelineProgressWidth] =
    useState(labourProgressData.length > 1 ? progressBarWidth : 0);
  // Pixels per minute ratio for the timeline: This value represents how many pixels correspond to one minute on the timeline.
  // It's used to calculate accurate spacing between observations based on their time differences.
  const pixelsPerMinutesInTimeline =
    ProgressPlayerCalculation.CalculatePixelsPerMinuteInTimeline(
      progressBarWidth,
      labourProgressData
    );
  // Controls the visibility of the play/pause button.
  const [showPlayButton, setShowPlayButton] = useState(true);
  // Tracks the current observation point from the array as the player progresses.
  const [currentObservationPoint, setCurrentObservationPoint] = useState(0);
  // Indicates whether the player is currently in progress.
  const [isPlayerInProgress, setIsPlayerInProgress] = useState(false);
  // Interval in milliseconds for refreshing player data
  const playerRefreshInterval = 3000;

  // Hooks
  const { addToast } = useToast();

  // Functions

  // Calculates the position of the circle on the timeline for a specific labor data object in the labor progress data array.
  // Requires the index in the array since the position for the circle is predefined for the first object. This function
  // computes the duration in minutes between the first point and the current point, multiplying it by the precomputed
  // pixels-per-minute variable to determine the exact position of each object on the timeline.
  const calculateDataStepPositionInXaxisByIndexInDataArray = (
    indexInArray: number
  ) => {
    const timeBetween =
      indexInArray >= 1
        ? ProgressPlayerCalculation.CalculateDurationBetweenTimepointsInMinutes(
            labourProgressData[0],
            labourProgressData[indexInArray]
          )
        : 0;
    return timeBetween * pixelsPerMinutesInTimeline;
  };

  // Updating the values in the visualization components for a specific labor progress object.
  const setAssessmentValuesInVisualizations = useCallback(
    (item: LabourProgressDto) => {
      // Setting baby body position
      setBabyBodyPositionFromState(item.babyBodyPosition);
      // Setting body rotation
      setBabyBodyRotationFromState(item.babyBodyRotation);
      // Setting baby head position
      setBabyHeadPositionFromState(item.babyHeadPosition);
      // Setting baby descent level
      setBabyDescentLevelFromState(Number(item.babyBodyDescentLevel));
      // Setting cervix opening
      setCervixOpeningFromState(item.cervixOpeningLevel);
    },
    [
      setBabyBodyPositionFromState,
      setBabyBodyRotationFromState,
      setBabyDescentLevelFromState,
      setBabyHeadPositionFromState,
      setCervixOpeningFromState,
    ]
  );

  // This function updates the circle indicator's position on the timeline based on the play animation state.
  // It also draws the current timeline progress in sync with this state. Additionally, it adjusts the values
  // in the visualization components and manages the display of the play and pause buttons accordingly.
  const positionAssesmentInProgressBar = useCallback(
    (item: LabourProgressDto, index: number) => {
      // Adjusting the circle position.
      setCurrentProgressCirclePosX(
        initialProgressCirclePosX +
          (item.assessmentPosX || 0) -
          (index > 1 ? extraBufferDueToPxCalculation : 0)
      );
      // Adjusting the progress line width.
      setCurrentTimelineProgressWidth(item.assessmentPosX || 0);
      // Updating the visualization components with data from a specific labor progress object
      setAssessmentValuesInVisualizations(item);
      // When the last item is reached, display the play button, halt the player's progress on the timeline,
      // and remove the overlay layer that visually isolates the components during playback. All this happens after
      // the playerRefreshInterval has passed.
      if (item.isLast) {
        setTimeout(() => {
          setShowPlayButton(true);
          setIsPlayerInProgress(false);
          setIsPlayerModeActive(false);
          setCurrentObservationPoint(0);
        }, playerRefreshInterval);
      }
    },
    [setAssessmentValuesInVisualizations, setIsPlayerModeActive]
  );

  // Function to enable or disable the progress player component (including Play/Pause buttons).
  const activateProgressPlayer = (activate: boolean) => {
    // Activates or deactivates the progress player component and manages the visibility of play/pause buttons.
    // If 'activate' is true, the progress player is activated, hiding the play button and showing the pause button.
    // If 'activate' is false, the progress player is deactivated.
    // It also shows the overlay layer that visually isolates the components during playback.
    setIsPlayerInProgress(activate);
    setShowPlayButton(!activate);
    setIsPlayerModeActive(true);
  };

  // Effects

  useEffect(() => {
    let interval: NodeJS.Timer | undefined;
    // During playback, positions each assessment from the labor progress data array on the progress bar
    // at intervals of X seconds defined by the playerRefreshInterval variable. When playback stops,
    // the interval is cleared, and no updates occur.
    if (isPlayerInProgress) {
      interval = setInterval(
        () => {
          var labourProgressItem: LabourProgressDto =
            labourProgressData[currentObservationPoint];
          positionAssesmentInProgressBar(
            labourProgressItem,
            currentObservationPoint + 1
          );
          setCurrentObservationPoint(currentObservationPoint + 1);
        },
        currentObservationPoint === 0 ? 0 : playerRefreshInterval
      );
    } else {
      setIsPlayerInProgress(false);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    isPlayerInProgress,
    currentObservationPoint,
    labourProgressData,
    positionAssesmentInProgressBar,
    setIsPlayerInProgress,
  ]);

  useEffect(() => {
    // Refreshing the labor progress data array from local storage if flagged
    if (refreshLabourProgressDataFromStorage) {
      // Retrieve updated data array from local storage
      const refreshedDataArray = refreshLabourDataFromLocalStorage();
      // Update state with refreshed data
      setLabourProgressData(refreshedDataArray);
      // Reset refresh flag after data retrieval
      setRefreshLabourProgressDataFromStorage(false);
      // Adjust timeline progress line width based on array length
      setCurrentTimelineProgressWidth(
        refreshedDataArray.length > 1 ? progressBarWidth : 0
      );
      // Set position of progress circle on timeline based on array length and initial position
      setCurrentProgressCirclePosX(
        initialProgressCirclePosX +
          (refreshedDataArray.length > 1
            ? progressBarWidth - extraBufferDueToPxCalculation
            : 0)
      );
    }
  }, [
    currentTimelineProgressWidth,
    refreshLabourProgressDataFromStorage,
    setLabourProgressData,
    setRefreshLabourProgressDataFromStorage,
  ]);

  // Update positions and properties for each item in the labour progress data array
  labourProgressData.forEach((item: LabourProgressDto, index: number) => {
    // Calculate and assign X-axis position for the current data step
    const itemPositionX =
      calculateDataStepPositionInXaxisByIndexInDataArray(index);
    // Adjust assessment time label position to center it relative to the item position on the X-axis
    item.assessmentTimeLabelPosX = itemPositionX + 15;
    // Adjust progress handle position on the timeline; apply a buffer adjustment for the first observation
    item.progressHandleBarPosX =
      itemPositionX - (index > 0 ? extraBufferDueToPxCalculation : 0);
    // Set flag to indicate if the item is the last in the list, for styling purposes
    item.isLast = index === labourProgressData.length - 1;
    // Set current X-axis position of the assessment, used for circle placement and progress bar width calculation
    item.assessmentPosX = itemPositionX;
  });

  return (
    <div
      className={`progress-player-container ${
        isPlayerModeActive ? "z-index-to-top" : ""
      }`}
    >
      <svg
        width="482"
        height="58"
        viewBox="0 0 482 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Start: Play/Pause icons */}
        <g
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (labourProgressData.length > 0) {
              activateProgressPlayer(showPlayButton);
            } else {
              // Displaying a toast message to inform the user that the player cannot
              // start playing due to lack of registered data.
              return Global.showMessageBox(
                addToast,
                "Ingen data er registrert",
                ToastMessageType.Warning
              );
            }
          }}
          width={40}
          height={40}
        >
          {showPlayButton && (
            <g transform={`translate(9, 22)`}>
              <SystemIcons.Play color={COLORS.neutral_600}></SystemIcons.Play>
            </g>
          )}
          {!showPlayButton && (
            <g transform={`translate(12, 22)`}>
              <SystemIcons.Pause color={COLORS.neutral_600}></SystemIcons.Pause>
            </g>
          )}
          <rect
            transform={`translate(7, 14)`}
            width={40}
            height={40}
            fill="transparent"
          ></rect>
        </g>
        {/* End: Play/Pause icons */}

        {/* Start: Starting limit line on the timeline */}
        <rect
          x={initialTimelineBarPosX}
          y="34"
          width="2"
          height="8"
          rx="1"
          fill="#949494"
          visibility={
            labourProgressData.length > 0
              ? Visibility.Visible
              : Visibility.Hidden
          }
        />
        {/* End: Starting limit line on the timeline */}

        {/* Start: Final limit line on the timeline */}
        <rect
          x={
            initialTimelineBarPosX +
            progressBarWidth -
            extraBufferDueToPxCalculation
          }
          y="34"
          width="2"
          height="8"
          rx="1"
          fill="#949494"
          visibility={
            labourProgressData.length > 0
              ? Visibility.Visible
              : Visibility.Hidden
          }
        />
        {/* End: Final limit line on the timeline */}

        {/* Start: Main line in timeline */}
        <rect
          x={initialTimelineBarPosX}
          y="31"
          width={progressBarWidth}
          height="6"
          rx="3"
          fill="#E5E5E5"
        />
        {/* End: Main line in timeline */}

        {/* Start: Progress line on top of the timeline */}
        <rect
          x={initialTimelineBarPosX}
          y="31"
          width={currentTimelineProgressWidth}
          height="6"
          rx="3"
          fill="#2E7FA1"
        />
        {/* End: Progress line on top of the timeline */}

        {/* Start: Drawing vertical steps and the current time of the progress in the timeline */}
        <>
          {labourProgressData.map((item: LabourProgressDto, index: number) => (
            <React.Fragment key={index}>
              {/* Start: Current time of the progress in the timeline */}
              <text
                fill="#666"
                fontFamily="Lato"
                fontSize="12"
                fontStyle="normal"
                fontWeight={
                  (index === currentObservationPoint - 1 && !item.isLast) ||
                  (item.isLast && currentObservationPoint === 0)
                    ? 900
                    : 400
                }
                pointerEvents="none"
                textAnchor="end"
                x={initialTimelineBarPosX + (item.assessmentTimeLabelPosX || 0)}
                y="15"
                style={{ userSelect: "none" }}
              >
                <tspan>
                  {Global.formatTimeFromString(item.assessmentTime)}
                </tspan>
              </text>
              {/* End: Current time of the progress in the timeline */}

              {/* Start: Small vertical line that indicates the progress step in the timeline */}
              <rect
                x={initialTimelineBarPosX + (item.progressHandleBarPosX || 0)}
                y="20"
                width="2"
                height="8"
                rx="1"
                fill="#2E7FA1"
              />
              {/* End: Small vertical line that indicates the progress step in the timeline */}
            </React.Fragment>
          ))}
        </>
        {/* End: Drawing vertical steps and the current time of the progress in the timeline */}

        {/* Start: Circle on top of the timeline that indicates the current time of the progress */}
        <circle
          visibility={progressCircleVisibility}
          cx={currentProgressCirclePosX}
          cy="34"
          r="8"
          fill="#276D8B"
        />
        {/* End: Circle on top of the timeline that indicates the current time of the progress */}
      </svg>
    </div>
  );
};

export default ProgressPlayer;
