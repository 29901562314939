import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { LabourProgressDto } from "../dtos/LabourProgressDto";

// Extend dayjs with customParseFormat plugin for flexible time parsing
dayjs.extend(customParseFormat);

export default class ProgressPlayerCalculation {
  // Calculates the duration in minutes between two timepoints.
  static CalculateDurationBetweenTimepointsInMinutes = (
    firstTimepoint: LabourProgressDto,
    lastTimepoint: LabourProgressDto
  ) => {
    // Convert assessment times to dayjs objects
    const startTimeDayJS = dayjs(firstTimepoint.assessmentTime);
    const endTimeDayJS = dayjs(lastTimepoint.assessmentTime);

    // Calculate total duration in milliseconds and convert to minutes
    const totalProgressDuration = endTimeDayJS.diff(startTimeDayJS);
    const durationInMinutes = Math.floor(totalProgressDuration / 60000);

    return durationInMinutes;
  };

  // Calculates the number of pixels that represent one minute on the timeline.
  static CalculatePixelsPerMinuteInTimeline = (
    timelineLength: number,
    labourProgressData: LabourProgressDto[]
  ) => {
    // Ensure there are at least two data points to calculate the total progress duration
    const totalDuration =
      labourProgressData.length > 1
        ? this.CalculateDurationBetweenTimepointsInMinutes(
            labourProgressData[0],
            labourProgressData[labourProgressData.length - 1]
          )
        : 0;

    // Calculate and return the pixels per minute value
    const pixelsPerMinute =
      totalDuration > 0 ? timelineLength / totalDuration : 0;
    return pixelsPerMinute;
  };
}
