import Dictionaries from "./Dictionaries";
import { BabyBodyPositions, BabyHeadPositions } from "./Enums";
import Global from "./Global";

export default class SVGImagePositioning {
  // About the acronyms: they are related to fetal positioning or belly mapping
  // http://www.earthboundtherapeutics.com/blog/fetal-positioning-aka-belly-mapping
  static getBabyBodyImagesInitialPosition = (
    position: string,
    rotation: string
  ) => {
    let posX = 0;
    let posY = 0;
    if (position === BabyBodyPositions.Cephalic) {
      switch (rotation) {
        case "LOA2":
          return { posX: 96, posY: 230 };
        case "LOT":
          return { posX: 80, posY: 240 };
        case "LOP4":
          return { posX: 95, posY: 231 };
        case "LOP5":
          return { posX: 92, posY: 239 };
        case "OP":
          return { posX: 102, posY: 239 };
        case "ROP7":
          return { posX: 116, posY: 239 };
        case "ROP8":
          return { posX: 114, posY: 231 };
        case "ROT":
          return { posX: 102, posY: 239 };
        case "ROA10":
          return { posX: 83, posY: 231 };
        default:
          // Position X & Y for the default image in Hodeleie
          return { posX: 102, posY: 229 };
      }
    } else if (position === BabyBodyPositions.Breech) {
      switch (rotation) {
        case "LSA1":
          return { posX: 110, posY: 228 };
        case "LSA2":
          return { posX: 105, posY: 229 };
        case "LST":
          return { posX: 95, posY: 237 };
        case "LSP4":
          return { posX: 110, posY: 229 };
        case "LSP5":
          return { posX: 110, posY: 236 };
        case "SP":
          return { posX: 110, posY: 236 };
        case "RSP7":
          return { posX: 106, posY: 236 };
        case "RSP8":
          return { posX: 106, posY: 229 };
        case "RST":
          return { posX: 95, posY: 237 };
        case "RSA10":
          return { posX: 83, posY: 229 };
        case "RSA11":
          return { posX: 100, posY: 227 };
        default:
          // Position X & Y for the default image in SeteFotLeie
          return { posX: 115, posY: 227 };
      }
    }
    return { posX, posY };
  };

  static getBabyHeadImagesInitialPosition = (position: string) => {
    switch (position) {
      case BabyHeadPositions.Centered:
        return { posX: 137, posY: 98 };
      case BabyHeadPositions.RightAsynclitic:
      case BabyHeadPositions.LeftAsynclitic:
        return { posX: 123, posY: 90 };
      case BabyHeadPositions.Breech:
        return { posX: 112, posY: 90 };
      default:
        return { posX: 137, posY: 98 };
    }
  };

  static getAngleByBodyPositionAndBodyRotation = (
    babyPosition: string,
    babyRotation: string
  ) => {
    const angle =
      babyPosition === BabyBodyPositions.Cephalic
        ? Global.getKeyFromObjectValue(
            Dictionaries.rotationLabelInternalValuesByAngleAndCephalicPosition,
            babyRotation
          )
        : Global.getKeyFromObjectValue(
            Dictionaries.rotationLabelInternalValuesByAngleAndBreechPosition,
            babyRotation
          );
    return Number(angle);
  };
}
