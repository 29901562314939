import CervixDescent from "./CervixDescent";
import {
  BasicDropdown,
  DropdownContentContainer,
  Size,
} from "@laerdal/life-react-components";
import {
  BabyBodyPositions,
  BabyHeadPositions,
  BreechPositions,
  CephalicPositions,
} from "../helpers/Enums";
import styled from "styled-components";
import RotationAndOpening from "./RotationAndOpening";
import {
  LocalStorageKey,
  removeKeyFromLocalStorage,
} from "../helpers/LocalStorage";
import { useEffect } from "react";
import DropDownDatasources from "../helpers/DropDownDatasources";
import useLabourViewStore from "../helpers/StateManager";

// Styled components for dropdown
const DescentDropDownContainer = styled.div`
  ${DropdownContentContainer} {
    min-width: 223px;
  }
`;

const Visualizations = () => {
  // Variables
  const descentPositionValues = DropDownDatasources.getDescentPosition();
  const asyncliticPositionValues =
    DropDownDatasources.getAsyncliticPositionValues();
  const cervixOpeningValues = DropDownDatasources.getCervixOpeningValues();

  // Boolean flag for temporary used. The asynclitic dropdown will be hidden at first when the
  // pilot of the project starts, but it can be reverted if the decision is made
  const notToBeUsedInPilot = true;

  // Global state managers

  // State manager for the baby body position.
  const babyBodyPosition = useLabourViewStore(
    (state) => state.babyBodyPosition
  );
  const setBabyBodyPosition = useLabourViewStore(
    (state) => state.setBabyBodyPosition
  );

  // State manager for the baby head position.
  const babyHeadPosition = useLabourViewStore(
    (state) => state.babyHeadPosition
  );
  const setBabyHeadPosition = useLabourViewStore(
    (state) => state.setBabyHeadPosition
  );

  // State manager for the cervix opening.
  const cervixOpening = useLabourViewStore((state) => state.cervixOpening);
  const setCervixOpening = useLabourViewStore(
    (state) => state.setCervixOpening
  );

  // State manager for the baby body rotation.
  const setBabyBodyRotation = useLabourViewStore(
    (state) => state.setBabyBodyRotation
  );

  // State manager for the baby descent level.
  const setBabyDescentLevel = useLabourViewStore(
    (state) => state.setBabyDescentLevel
  );

  // State manager for resetting visualization data.
  const resetVisualizationsData = useLabourViewStore(
    (state) => state.resetVisualizationsData
  );
  const setResetVisualizationsData = useLabourViewStore(
    (state) => state.setResetVisualizationsData
  );

  // State manager for tracking if the player mode is active.
  const isPlayerInProgress = useLabourViewStore(
    (state) => state.isPlayerModeActive
  );

  useEffect(() => {
    // if the reset button (in the action bar component) is triggered, then this block of code
    // where the reset data for the calculator happens, will be executed
    if (resetVisualizationsData) {
      // Removing the labour progress data array from local storage when resetting new patient
      removeKeyFromLocalStorage(LocalStorageKey.LabourProgressDataArray);

      // Resetting variables used in the visualization components to their default values
      // Setting baby body position
      setBabyBodyPosition(BabyBodyPositions.Cephalic);
      // Setting body rotation
      setBabyBodyRotation(CephalicPositions.OcciputAnterior);
      // Setting baby head position
      setBabyHeadPosition(BabyHeadPositions.Centered);
      // Setting baby descent level
      setBabyDescentLevel(-5);
      // Setting cervix opening
      setCervixOpening("5");

      // Setting back the setResetVisualizationsData (in the parent component) to false value, to let the parent component
      // know that the resetting of the data has happened
      setResetVisualizationsData(false);
    }
  }, [
    resetVisualizationsData,
    setBabyBodyPosition,
    setBabyBodyRotation,
    setBabyDescentLevel,
    setBabyHeadPosition,
    setCervixOpening,
    setResetVisualizationsData,
  ]);

  return (
    <div className={isPlayerInProgress ? "z-index-to-top" : ""}>
      <DescentDropDownContainer>
        <div className="visualizations-container">
          <div className="descent-visualization-main-container">
            <span className="title">Nivå</span>
            <div className="inner-container">
              <div className="top-bar-container">
                <div className="dropdown-container">
                  <BasicDropdown
                    id="basicDropdown"
                    list={descentPositionValues}
                    value={babyBodyPosition}
                    size={Size.Small}
                    onSelect={(value) => {
                      // Setting the new baby position selected by the user
                      setBabyBodyPosition(value);

                      // The baby's head position has to be changed according to the baby position selected
                      // by the user. If the baby is coming down cephalic, then we show the baby head image
                      // in the rotation and opening visualization. If the baby comes down in a breech position
                      // then we show the bump image
                      if (value === BabyBodyPositions.Cephalic) {
                        setBabyHeadPosition(BabyHeadPositions.Centered);
                        setBabyBodyRotation(CephalicPositions.OcciputAnterior);
                      } else if (value === BabyBodyPositions.Breech) {
                        setBabyHeadPosition(BabyHeadPositions.Breech);
                        setBabyBodyRotation(BreechPositions.SacrumAnterior);
                      }
                    }}
                    minWidth="223px"
                    disabled={isPlayerInProgress}
                  />
                </div>
              </div>
              <div className="image-container">
                <CervixDescent />
              </div>
            </div>
          </div>
          <div className="rotation-visualization-main-container">
            <span className="title">Posisjon og mormunnsåpning</span>
            <div className="inner-container">
              <div className="top-bar-container">
                <div className="dropdown-container">
                  {/* This dropdown is initially unused during the Pilot phase, but retained here for potential future use */}
                  {babyBodyPosition === BabyBodyPositions.Cephalic &&
                    !notToBeUsedInPilot && (
                      <BasicDropdown
                        id="basicDropdown"
                        list={asyncliticPositionValues}
                        value={babyHeadPosition}
                        size={Size.Small}
                        onSelect={(value) => {
                          setBabyHeadPosition(value);
                        }}
                        minWidth="223px"
                        disabled={isPlayerInProgress}
                      />
                    )}
                </div>
                <div className="dropdown-container">
                  <BasicDropdown
                    id="basicDropdown"
                    list={cervixOpeningValues}
                    value={cervixOpening}
                    size={Size.Small}
                    onSelect={(value) => {
                      setCervixOpening(value);
                    }}
                    minWidth="223px"
                    disabled={isPlayerInProgress}
                  />
                </div>
              </div>
              <div className="image-container">
                <RotationAndOpening />
              </div>
            </div>
          </div>
        </div>
      </DescentDropDownContainer>
    </div>
  );
};

export default Visualizations;
