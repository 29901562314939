import {
  SystemIcons,
  ToastColor,
  ToastEntryDirection,
  ToastOptions,
  ToastPosition,
} from "@laerdal/life-react-components";
import { ToastMessageType } from "./Enums";
import dayjs from "dayjs";

export default class Global {
  // Function to check if the time value has the proper format expected for the input
  static checkTimeFormat = (timeValue: string) => {
    const re = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return timeValue && re.test(timeValue);
  };

  // Returns the color associated with the given toast message type.
  static GetToastColor(toastType?: ToastMessageType) {
    switch (toastType) {
      case ToastMessageType.Info:
        return ToastColor.BLUE;
      case ToastMessageType.Warning:
        return ToastColor.ORANGE;
      case ToastMessageType.Error:
        return ToastColor.RED;
      default:
        return ToastColor.GREEN;
    }
  }

  // Returns the icon associated with the given toast message type.
  static GetToastIcon(toastType?: ToastMessageType) {
    switch (toastType) {
      case ToastMessageType.Info:
        return <SystemIcons.Information size="24px" />;
      case ToastMessageType.Warning:
        return <SystemIcons.TechnicalWarning size="24px" />;
      case ToastMessageType.Error:
        return <SystemIcons.TechnicalWarning size="24px" />;
      default:
        return <SystemIcons.CheckMark size="24px" />;
    }
  }

  // Function to show message boxes according to the proper type (success, warning, error)
  static showMessageBox = (
    addToast: (content: any, options: ToastOptions) => string,
    message: string,
    toastType?: ToastMessageType
  ) => {
    // Showing the toast and returning the toast id to be use when removing it is required
    return addToast(message, {
      color: this.GetToastColor(toastType),
      showCloseButton: true,
      autoClose: true,
      position: ToastPosition.BOTTOMMIDDLE,
      enterFrom: ToastEntryDirection.BOTTOM,
      icon: this.GetToastIcon(toastType),
      delay: 10000,
    });
  };

  static getKeyFromObjectValue = (obj: Object, value: any) => {
    return Object.keys(obj)[Object.values(obj).indexOf(value)];
  };

  static formatDateTimeFromString = (timeString: string) => {
    return dayjs(timeString).format("YYYY-MM-DD HH:mm");
  }

  static formatTimeFromString = (timeString: string) => {
    return dayjs(timeString).format("HH:mm");
  }
}
