import {
  BasicDropdown,
  ModalDialog,
  Size,
  useToast,
} from "@laerdal/life-react-components";
import { useEffect, useState } from "react";
import {
  BabyBodyPositions,
  BabyHeadPositions,
  BreechPositions,
  CephalicPositions,
  ToastMessageType,
} from "../../helpers/Enums";
import { LabourProgressDto } from "../../dtos/LabourProgressDto";
import {
  LocalStorageKey,
  refreshLabourDataFromLocalStorage,
  useLocalStorage,
} from "../../helpers/LocalStorage";
import DropDownDatasources from "../../helpers/DropDownDatasources";
import Global from "../../helpers/Global";
import dayjs from "dayjs";
import DateTimePicker from "../DateTimePicker";
import useLabourViewStore from "../../helpers/StateManager";

interface Props {
  openWindow: boolean;
  setOpenWindow: (newValue: boolean) => void;
}

const RegisterDataModal = ({ openWindow, setOpenWindow }: Props) => {
  // Variables

  // Global state managers

  // State manager for the baby body position.
  const babyBodyPositionFromState = useLabourViewStore(
    (state) => state.babyBodyPosition
  );
  const setBabyBodyPositionFromState = useLabourViewStore(
    (state) => state.setBabyBodyPosition
  );

  // State manager for the baby head position.
  const babyHeadPositionFromState = useLabourViewStore(
    (state) => state.babyHeadPosition
  );
  const setBabyHeadPositionFromState = useLabourViewStore(
    (state) => state.setBabyHeadPosition
  );

  // State manager for the current level of descent for the baby.
  const babyDescentLevelFromState = useLabourViewStore(
    (state) => state.babyDescentLevel
  );
  const setbabyDescentLevelFromState = useLabourViewStore(
    (state) => state.setBabyDescentLevel
  );

  // State manager for the cervix opening.
  const cervixOpeningFromState = useLabourViewStore(
    (state) => state.cervixOpening
  );
  const setCervixOpeningFromState = useLabourViewStore(
    (state) => state.setCervixOpening
  );

  // Represents the current rotation of the baby's body.
  const babyBodyRotationFromState = useLabourViewStore(
    (state) => state.babyBodyRotation
  );
  const setBabyBodyRotationFromState = useLabourViewStore(
    (state) => state.setBabyBodyRotation
  );

  // State manager for refresihng labour progress data from storage.
  const refreshLabourProgressDataFromStorage = useLabourViewStore(
    (state) => state.refreshLabourProgressDataFromStorage
  );
  const setRefreshLabourProgressDataFromStorage = useLabourViewStore(
    (state) => state.setRefreshLabourProgressDataFromStorage
  );

  // Registration data form local variables
  const [removeFocusFromTimeInput, setRemoveFocusFromTimeInput] =
    useState(false);
  const [registerDataTime, setRegisterDataTime] = useState("");
  const [babyBodyPosition, setBabyBodyPosition] = useState<string>(
    babyBodyPositionFromState
  );
  const [babyHeadPosition, setBabyHeadPosition] = useState<string>(
    babyHeadPositionFromState
  );
  const [babyDescentLevel, setBabyDescentLevel] = useState(
    babyDescentLevelFromState.toString()
  );
  const [cervixOpening, setCervixOpening] = useState(cervixOpeningFromState);
  const [babyRotationPosition, setBabyRotationPosition] = useState<string>(
    babyBodyRotationFromState
  );

  // Values for the different dropdowns
  const descentPositionValues = DropDownDatasources.getDescentPosition();
  const babyDescentLevelValues =
    DropDownDatasources.getBabyDescentLevelValues();
  const asyncliticPositionValues =
    DropDownDatasources.getAsyncliticPositionValues();
  const cervixOpeningValues = DropDownDatasources.getCervixOpeningValues();

  // Others
  const [labourProgressData, setLabourProgressData] = useLocalStorage(
    LocalStorageKey.LabourProgressDataArray,
    []
  );
  const [currentOpenToastId, setCurrentOpenToastId] = useState("");
  const [
    shouldLoadDataFromVisualizations,
    setShouldLoadDataFromVisualizations,
  ] = useState(true);

  // Temporary boolean flag. Initially hides the asynclitic dropdown during the pilot of the project,
  // but can be reversed based on future decisions.
  const notToBeUsedInPilot = true;

  // Hooks
  const { addToast, removeToast } = useToast();

  // Functions

  // Close the register data modal window
  const CloseRegisterDataModal = () => {
    // Set openWindow flag to false to indicate modal closure
    openWindow = false;
    // Close the modal window by setting openWindow state to false
    setOpenWindow(false);
    // Trigger data reload from visualizations by setting this flag to true
    setShouldLoadDataFromVisualizations(true);
  };

  // Function to retrieve baby rotation position values based on baby body position
  const GetBabyRotationPositionValues = () => {
    if (babyBodyPosition === BabyBodyPositions.Cephalic) {
      // Return rotation values specific to cephalic position from data source
      return DropDownDatasources.getBabyRotationByCephalicPositionValues();
    } else {
      // Return rotation values specific to breech position from data source
      return DropDownDatasources.getBabyRotationByBreechPositionValues();
    }
  };

  // Function to validate the registered time input.
  const ValidateRegisteredTime = () => {
    // Check if the selected latest time is empty
    if (registerDataTime === "") {
      // Return a warning message if the time is empty
      return {
        message: "Legg til tidspunkt for måling",
        messageType: ToastMessageType.Warning,
      };
    }
  };

  // Handles the registration or updating of labor progress data.
  const RegisterData = () => {
    let toastId = "";

    // Remove any existing toast to prevent multiple windows on the screen
    removeToast(currentOpenToastId);

    // Validate the registered time input
    const validationResults = ValidateRegisteredTime();
    let filteredLabourProgressData: LabourProgressDto[] = [];

    if (validationResults) {
      // Show a message box with validation results if there are errors
      toastId = Global.showMessageBox(
        addToast,
        validationResults.message,
        validationResults.messageType
      );
    } else {
      // Create an object with data to be stored
      const labourProgressInstance: LabourProgressDto = {
        assessmentTime: registerDataTime,
        isAssessmentOnTheDayBefore: !dayjs(registerDataTime).isToday(),
        babyBodyPosition: babyBodyPosition,
        babyBodyDescentLevel: babyDescentLevel,
        babyBodyRotation: babyRotationPosition,
        babyHeadPosition: babyHeadPosition,
        cervixOpeningLevel: cervixOpening,
      };

      // Filter out existing labour progress data with the same assessment time. Replace existing assessments with the latest
      // version for the current time.
      filteredLabourProgressData = labourProgressData.filter(
        (item: LabourProgressDto) =>
          Global.formatDateTimeFromString(item.assessmentTime) !==
          Global.formatDateTimeFromString(registerDataTime)
      );

      // Add the new instance to the filtered labour progress data
      filteredLabourProgressData.push(labourProgressInstance);
      // Sort the array by assessment time to ensure chronological order in the progress timeline
      filteredLabourProgressData.sort((a, b) => {
        return dayjs(a.assessmentTime).isAfter(dayjs(b.assessmentTime))
          ? 1
          : -1;
      });
      // Store the sorted data array in local storage
      setLabourProgressData(filteredLabourProgressData);
      // Set a flag to refresh labour progress data from local storage when needed
      setRefreshLabourProgressDataFromStorage(true);

      // Update visualization components with new data values

      // Set baby body position
      setBabyBodyPositionFromState(babyBodyPosition);
      // Set body rotation
      setBabyBodyRotationFromState(babyRotationPosition);
      // Set baby head position
      setBabyHeadPositionFromState(babyHeadPosition);
      // Set baby descent level
      setbabyDescentLevelFromState(Number(babyDescentLevel));
      // Set cervix opening
      setCervixOpeningFromState(cervixOpening);

      // Close the registration modal window
      CloseRegisterDataModal();
    }

    // Set the current toast ID in the state for display purposes
    setCurrentOpenToastId(toastId);
  };

  // Effect

  useEffect(() => {
    // Refresh labour progress data from local storage if flag is set
    if (refreshLabourProgressDataFromStorage) {
      setLabourProgressData(refreshLabourDataFromLocalStorage());
      // Reset flag after updating data from storage
      setRefreshLabourProgressDataFromStorage(false);
    }

    // Set local modal window values when state values change and modal is open
    // Ensures data is loaded only once per modal opening when visualization data should be loaded.
    if (openWindow && shouldLoadDataFromVisualizations) {
      // Set baby body position from state
      setBabyBodyPosition(babyBodyPositionFromState);
      // Set body rotation from state
      setBabyRotationPosition(babyBodyRotationFromState);
      // Set baby head position from state
      setBabyHeadPosition(babyHeadPositionFromState);
      // Set baby descent level from state
      setBabyDescentLevel(babyDescentLevelFromState.toString());
      // Set cervix opening from state
      setCervixOpening(cervixOpeningFromState);
      // Reset flag to prevent redundant data loading
      setShouldLoadDataFromVisualizations(false);
    }
  }, [
    babyBodyPositionFromState,
    babyBodyRotationFromState,
    babyDescentLevelFromState,
    babyHeadPositionFromState,
    cervixOpeningFromState,
    openWindow,
    refreshLabourProgressDataFromStorage,
    setLabourProgressData,
    setRefreshLabourProgressDataFromStorage,
    shouldLoadDataFromVisualizations,
  ]);

  return (
    <ModalDialog
      key="RegisterDataModalDialog"
      title={"Registrer"}
      size={Size.Small}
      isModalOpen={openWindow}
      closeAction={CloseRegisterDataModal}
      shouldCloseOnOverlayClick={false}
      submitAction={() => {}}
      buttons={[
        {
          action: () => {
            RegisterData();
          },
          text: "Registrer",
          variant: "primary",
        },
      ]}
    >
      <div className="register-data-form-container">
        <div className="input-label-container">
          <div className="input-title">Tidspunkt</div>
          <DateTimePicker
            removeFocusFromTimeInput={removeFocusFromTimeInput}
            setRemoveFocusInParentComponent={setRemoveFocusFromTimeInput}
            setSelectedLatestTime={setRegisterDataTime}
            isInModal={true}
            canPickFutureDateTime={false}
          />
        </div>
        <div className="input-label-container">
          <div className="input-title">Presentasjon</div>
          <BasicDropdown
            id="basicDropdown"
            list={descentPositionValues}
            value={babyBodyPosition}
            size={Size.Small}
            onSelect={(value) => {
              setBabyBodyPosition(value);
              // Updating the default value of the rotation (position) dropdown
              if (value === BabyBodyPositions.Cephalic) {
                setBabyRotationPosition(CephalicPositions.OcciputAnterior);
                setBabyHeadPosition(BabyHeadPositions.Centered);
              } else {
                setBabyRotationPosition(BreechPositions.SacrumAnterior);
                setBabyHeadPosition(BabyHeadPositions.Breech);
              }
            }}
          />
        </div>
        <div className="input-label-container">
          <div className="input-title">Nivå</div>
          <BasicDropdown
            id="basicDropdown"
            list={babyDescentLevelValues}
            value={babyDescentLevel}
            size={Size.Small}
            onSelect={(value) => {
              setBabyDescentLevel(value);
            }}
          />
        </div>
        <div className="input-label-container">
          <div className="input-title">Mormunnsåpning</div>
          <BasicDropdown
            id="openingDropdown"
            list={cervixOpeningValues}
            value={cervixOpening}
            size={Size.Small}
            onSelect={(value) => {
              setCervixOpening(value);
            }}
          />
        </div>
        {/* This dropdown is initially unused during the Pilot phase, but retained here for potential future use */}
        {babyBodyPosition === BabyBodyPositions.Cephalic &&
          !notToBeUsedInPilot && (
            <div className="input-label-container">
              <div className="input-title">Synklitism</div>
              <BasicDropdown
                id="asyncliticPositionDropdown"
                list={asyncliticPositionValues}
                value={babyHeadPosition}
                size={Size.Small}
                onSelect={(value) => {
                  setBabyHeadPosition(value);
                }}
              />
            </div>
          )}
        <div className="input-label-container">
          <div className="input-title">Posisjon</div>
          <BasicDropdown
            id="basicDropdown"
            list={GetBabyRotationPositionValues()}
            value={babyRotationPosition}
            size={Size.Small}
            onSelect={(value) => {
              setBabyRotationPosition(value);
            }}
          />
        </div>
      </div>
    </ModalDialog>
  );
};

export default RegisterDataModal;
